import './Register.css'
import {Button, Divider, Form, Image, Input, message} from "antd";
import logo from '../../../Assets/Images/logo.png'
import {FacebookFilled} from "@ant-design/icons";
import {ReactComponent as GoogleLogo} from '../../../Assets/Images/google-logo.svg'
import {Link, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {useGoogleLogin} from "@react-oauth/google";
import useToken from "../../../hooks/useToken";
import useAuth from "../../../hooks/useAuth";
import Auth from "../../../services/auth.service";
import {FACEBOOK_AUTH_CONFIG, GOOGLE_AUTH_CONFIG} from "../../../constants";
import Users from "../../../services/users.service";

const Register = () => {
	let [email, setEmail] = useState("");
	let [password, setPassword] = useState("");
	let [confirmPassword, setConfirmPassword] = useState("");
	let [name, setName] = useState("");

	let [registerWithFacebookBtnLoading, setRegisterWithFacebookBtnLoading] = useState(false);
	let [registerWithGoogleBtnLoading, setRegisterWithGoogleBtnLoading] = useState(false);
	let [registerWithEmailBtnLoading, setRegisterWithEmailBtnLoading] = useState(false);

	let [registerWithFacebookBtnDisabled, setRegisterWithFacebookBtnDisabled] = useState(false);
	let [registerWithGoogleBtnDisabled, setRegisterWithGoogleBtnDisabled] = useState(false);
	let [registerWithEmailBtnDisabled, setRegisterWithEmailBtnDisabled] = useState(false);

	let {setToken} = useToken();
	let navigate = useNavigate();
	let {setIsAuthenticated} = useAuth();

	let getAccessTokenForSocialRegister = async (data) => {
		try {
			let response = await Auth.socialLogin(data);
			if (response.status === 200) {
				setToken('access_token', response?.data?.access_token);
				setIsAuthenticated(true);
				navigate("/onboarding");
				window.location.reload();
			}
		} catch (e) {
			setRegisterWithFacebookBtnLoading(false);
			setRegisterWithEmailBtnLoading(false);
			setRegisterWithFacebookBtnDisabled(false);
			setRegisterWithGoogleBtnDisabled(false);
			setRegisterWithGoogleBtnLoading(false);
			setRegisterWithEmailBtnDisabled(false);
			message.error("An error occurred.");
		}
	}

	let handleRegisterWithFacebook = async () => {
		/*
		* register with Facebook
		* */

		setRegisterWithFacebookBtnLoading(true);
		setRegisterWithFacebookBtnDisabled(true);
		setRegisterWithGoogleBtnDisabled(true);
		setRegisterWithEmailBtnDisabled(true);

		let form = new FormData();
		form.append("grant_type", "convert_token");
		form.append("client_id", FACEBOOK_AUTH_CONFIG.CLIENT_ID);
		form.append("backend", FACEBOOK_AUTH_CONFIG.BACKEND_NAME);

		try {
			window.FB.login(
				response => {
					form.append("token", response?.authResponse?.accessToken);

					getAccessTokenForSocialRegister(form);
				}, {scope: 'public_profile,email'}
			)
		} catch (e) {
			setRegisterWithEmailBtnLoading(false);
			setRegisterWithFacebookBtnDisabled(false);
			setRegisterWithFacebookBtnLoading(false);
			setRegisterWithGoogleBtnDisabled(false);
			setRegisterWithEmailBtnDisabled(false);
			message.error("An error occurred. Please check your internet connection and try again.");
		}
	}

	let loginWithGoogle = useGoogleLogin({
		onSuccess: tokenResponse => {
			let form = new FormData();
			form.append("grant_type", "convert_token");
			form.append("client_id", GOOGLE_AUTH_CONFIG.CLIENT_ID);
			form.append("backend", GOOGLE_AUTH_CONFIG.BACKEND_NAME);
			form.append("token", tokenResponse.access_token);
			getAccessTokenForSocialRegister(form);
		},
		onError: () => {
			setRegisterWithEmailBtnLoading(false);
			setRegisterWithFacebookBtnDisabled(false);
			setRegisterWithFacebookBtnLoading(false);
			setRegisterWithGoogleBtnDisabled(false);
			setRegisterWithGoogleBtnLoading(false);
			setRegisterWithEmailBtnDisabled(false);
			message.error("An error occurred.")
		},
		onNonOAuthError: () => {
			setRegisterWithEmailBtnLoading(false);
			setRegisterWithFacebookBtnDisabled(false);
			setRegisterWithFacebookBtnLoading(false);
			setRegisterWithGoogleBtnDisabled(false);
			setRegisterWithGoogleBtnLoading(false);
			setRegisterWithEmailBtnDisabled(false);
			message.error("An error occurred.");
		},
	});

	let handleRegisterWithGoogle = async () => {
		setRegisterWithGoogleBtnLoading(true);
		setRegisterWithFacebookBtnDisabled(true);
		setRegisterWithGoogleBtnDisabled(true);
		setRegisterWithEmailBtnDisabled(true);

		loginWithGoogle();
	}

	let handleRegisterWithEmail = async () => {
		if (email === "" || email.length <= 0) {
			message.error("Please enter a valid email");
			return;
		}

		if (password === "" || password.length <= 0) {
			message.error("Please enter a valid password");
			return;
		}

		if (password.length < 8) {
			message.error("Password must be at least 8 characters long");
			return;
		}

		if (name === "" || name.length <= 0) {
			message.error("Please enter a valid name");
			return;
		}

		if (password !== confirmPassword) {
			message.error("Passwords do not match.");
			return;
		}

		setRegisterWithEmailBtnLoading(true);
		setRegisterWithFacebookBtnDisabled(true);
		setRegisterWithGoogleBtnDisabled(true);
		setRegisterWithEmailBtnDisabled(true);

		try {
			let response = await Users.create({
				email: email,
				password: password,
				first_name: name,
			})
			if (response.status === 201) {
				setToken('access_token', response?.data?.access_token);
				setIsAuthenticated(true);
				navigate("/onboarding");
				window.location.reload();
			}
		} catch (e) {
			setRegisterWithEmailBtnLoading(false);
			setRegisterWithFacebookBtnDisabled(false);
			setRegisterWithGoogleBtnDisabled(false);
			setRegisterWithEmailBtnDisabled(false);

			if (e.response && e.response.status === 400) {
				if (e?.response?.data?.error_description) {
					message.error(e?.response?.data?.error_description);
				}
				if (e?.response?.data?.email) {
					message.error(e?.response?.data?.email[0]);
				}
			} else {
				message.error("An error occurred. Please check your internet connection and try again.");
			}
		}
	}

	useEffect(() => {
		window.fbAsyncInit = () => {
			window.FB.init({
				appId            : FACEBOOK_AUTH_CONFIG.APP_ID,
				autoLogAppEvents : true,
				xfbml            : true,
				version          : 'v11.0'
			});
		};

		(function (d, s, id) {
			var js, fjs = d.getElementsByTagName(s)[0];
			if (d.getElementById(id)) { return; }
			js = d.createElement(s); js.id = id;
			js.src = "https://connect.facebook.net/en_US/sdk.js";
			fjs.parentNode.insertBefore(js, fjs);
		}(document, 'script', 'facebook-jssdk'));
	}, []);

	return (
		<div id="login-page" className="vh-100 d-flex justify-content-center align-items-center">
			<div className="p-4 m-3 shadow-lg rounded-1">
				<Image src={logo} preview={false} height={40} width={40}/>
				<h4 className="mb-2">Create a new account</h4>
				<div>
					<Button
						loading={registerWithFacebookBtnLoading}
						disabled={registerWithFacebookBtnDisabled}
						onClick={handleRegisterWithFacebook}
						style={{backgroundColor: "#1877F2"}}
						size="large"
						className="rounded-1 text-white border-0 mb-3"
						icon={<FacebookFilled style={{color: "white", fontSize: 24}}/>}
						block
					>
						Continue with Facebook
					</Button>

					<Button
						loading={registerWithGoogleBtnLoading}
						disabled={registerWithGoogleBtnDisabled}
						onClick={handleRegisterWithGoogle}
						size="large"
						className="rounded-1 border-0 text-dark"
						icon={<GoogleLogo height={24} width={24}/>}
						block
					>
						Sign up with Google
					</Button>
				</div>

				<Divider className="border-light m-1" plain>
					Or
				</Divider>

				<Form
					layout="vertical"
				>
					<Form.Item
						name="email"
					>
						<Input
							size="large"
							placeholder="Email"
							className="rounded-1 border-0"
							onChange={e => setEmail(e.target.value)}
						/>
					</Form.Item>
					<Form.Item
						name="name"
					>
						<Input
							size="large"
							placeholder="Full name"
							className="rounded-1 border-0"
							onChange={e => setName(e.target.value)}
						/>
					</Form.Item>
					<Form.Item
						name="password"
					>
						<Input.Password
							size="large"
							placeholder="Password"
							className="rounded-1 border-0"
							onChange={e => setPassword(e.target.value)}
						/>
					</Form.Item>

					<Form.Item
						name="confirmPassword"
					>
						<Input.Password
							size="large"
							placeholder="Repeat password"
							className="rounded-1 border-0"
							onChange={e => setConfirmPassword(e.target.value)}
						/>
					</Form.Item>

					<Form.Item>
						<Button
							loading={registerWithEmailBtnLoading}
							disabled={registerWithEmailBtnDisabled}
							onClick={handleRegisterWithEmail}
							className="rounded-1 mt-1 border-0 text-dark"
							size="large"
							block
						>
							Create account
						</Button>
					</Form.Item>

					<div>
						<p className="m-0">
							Already have an account? <Link to="/login"> Login here.</Link>
						</p>
					</div>
				</Form>
			</div>
		</div>
	)
}

export default Register
