import Header from "../../components/Header";
import {Button, Checkbox, Divider, Drawer, Form, Input, message, Select} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {useEffect, useRef, useState} from "react";
import RolesService from "../../services/roles.service";
import useAuth from "../../hooks/useAuth";
import DivisionsService from "../../services/divisions.service";
import UsersService from "../../services/users.service";

const Team = () => {
	let {user} = useAuth();
	let [isNewTeamMemberDrawerOpen, setIsNewTeamMemberDrawerOpen] = useState(false);
	let [roles, setRoles] = useState(null);
	let [roleName, setRoleName] = useState("");
	let [isAddNewRoleBtnLoading, setIsAddNewRoleBtnLoading] = useState(false);
	let newRoleNameInputRef = useRef(null);
	let [divisions, setDivisions] = useState(null);
	let [isDivisionHead, setIsDivisionHead] = useState(false);
	let [inviteTeamMemberBtnLoading, setInviteTeamMemberBtnLoading] = useState(false);

	let onNameChange = (event) => {
		setRoleName(event.target.value);
	};

	let addNewRole = async (e) => {
		if (roleName === "") {
			message.error("Please enter a valid role name.")
			return;
		}

		e.preventDefault();
		try {
			setIsAddNewRoleBtnLoading(true)
			let response = await RolesService.create({
				workspace: user?.workspace?.id,
				name: roleName
			})
			if (response.status === 201) {
				setRoles([...roles, response.data]);
				setIsAddNewRoleBtnLoading(false)
			}
		} catch (e) {
			setIsAddNewRoleBtnLoading(false)
			message.error("An error occurred while creating role.");
		}
		setRoleName("");
		setTimeout(() => {
			newRoleNameInputRef.current?.focus();
		}, 0);
	};

	const onChangeIsDivisionHead = () => {
		setIsDivisionHead(!isDivisionHead)
	};

	let handleAddTeamMember = async (values) => {
		let data = {
			workspace: user?.workspace?.id,
			is_head_of_division: isDivisionHead,
			...values
		}

		setInviteTeamMemberBtnLoading(true);

		try {
			let response = await UsersService.inviteTeamMember(data);
			if (response.status === 201) {
				message.success("You have successfully invited the team member via email.");
				setInviteTeamMemberBtnLoading(false);
				setIsNewTeamMemberDrawerOpen(false);
			}
		} catch (e) {
			message.error("An error occurred. Team member not invited.");
			setInviteTeamMemberBtnLoading(false);
		}
	}

	useEffect(() => {
		let fetchRoles = async () => {
			try {
				let response = await RolesService.listRolesByWorkspaceId(user?.workspace?.id)
				return response.data;
			} catch (e) {
				return null
			}
		}
		let fetchDivisions = async () => {
			try {
				let response = await DivisionsService.listDivisionsByWorkspaceId(user?.workspace?.id)
				return response.data;
			} catch (e) {
				return null
			}
		}
		if (user?.workspace?.id) {
			fetchRoles()
				.then(response => setRoles(response))
		}
		if ((user?.workspace?.workspace_type === "COMPANY") && user?.workspace?.id) {
			fetchDivisions()
				.then(response => setDivisions(response))
		}
	}, [user?.workspace?.id, user?.workspace?.workspace_type])

	return (
		<div>
			<Header
				title="Team"
				rightActionComponent={
					<Button
						onClick={() => setIsNewTeamMemberDrawerOpen(!isNewTeamMemberDrawerOpen)}
						icon={<PlusOutlined />}
					>
						Add people
					</Button>
				}
			/>

			<Drawer
				title="Add and invite new team member"
				open={isNewTeamMemberDrawerOpen}
				onClose={() => setIsNewTeamMemberDrawerOpen(false)}
				size="large"
				destroyOnClose
				mask
			>
				<Form
					layout="vertical"
					onFinish={handleAddTeamMember}
				>
					<Form.Item
						rules={[{required: true}]}
						name="first_name"
						label="First name"
					>
						<Input placeholder="First name" size="large" />
					</Form.Item>
					<Form.Item
						rules={[{required: true}]}
						name="last_name"
						label="Last name"
					>
						<Input placeholder="Last name" size="large" />
					</Form.Item>
					<Form.Item
						rules={[{required: true}]}
						name="email"
						label="Email"
					>
						<Input placeholder="Email" size="large" />
					</Form.Item>
					<Form.Item
						rules={[{required: true}]}
						name="roles"
						label="Roles"
					>
						<Select
							dropdownRender={
								menu => (
									<>
										{menu}
										<Divider type="horizontal" className="my-3" />
										<div className="d-flex gap-3 p-3">
											<Input
												placeholder="Role name"
												ref={newRoleNameInputRef}
												value={roleName}
												onChange={onNameChange}
												onKeyDown={(e) => e.stopPropagation()}
											/>
											<Button
												loading={isAddNewRoleBtnLoading}
												disabled={isAddNewRoleBtnLoading}
												type="primary"
												icon={<PlusOutlined />}
												onClick={addNewRole}
											>
												Add new role
											</Button>
										</div>
									</>
								)
							}
							options={
								roles?.map((role) => ({
									value: role.id,
									label: role.name,
								}))
							}
							placeholder="Roles"
							size="large"
							mode="multiple"
						/>
					</Form.Item>
					{
						user?.workspace?.workspace_type === "COMPANY" && (
							<Form.Item
								rules={[{required: true}]}
								name="division"
								label="Division"
							>
								<Select
									placeholder="Division"
									size="large"
									options={
										divisions?.map((division) => ({
											value: division.id,
											label: `${division.name}(${division.division_type})`,
										}))
									}
								/>
							</Form.Item>
						)
					}

					{
						user?.workspace?.workspace_type === "COMPANY" && (
							<Form.Item
								label="Is head of division?"
							>
								<Checkbox checked={isDivisionHead} onChange={onChangeIsDivisionHead}>
									Is this user the head of the selected division?
								</Checkbox>
							</Form.Item>
						)
					}
					<Button
						loading={inviteTeamMemberBtnLoading}
						disabled={inviteTeamMemberBtnLoading}
						type="primary"
						size="large"
						htmlType="submit"
					>
						Add and invite new team member
					</Button>
				</Form>
			</Drawer>
		</div>
	)
}

export default Team
