const Dashboard = () => {
	return (
		<div>
			<h1 className="text-center fw-bolder">The Erector dashboard</h1>

		</div>
	)
}

export default Dashboard;
