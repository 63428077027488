import './Login.css'
import {Button, Divider, Form, Image, Input, message} from "antd";
import logo from '../../../Assets/Images/logo.png'
import {FacebookFilled} from "@ant-design/icons";
import {ReactComponent as GoogleLogo} from '../../../Assets/Images/google-logo.svg'
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {useGoogleLogin} from "@react-oauth/google";
import Auth from "../../../services/auth.service";
import useToken from "../../../hooks/useToken";
import useAuth from "../../../hooks/useAuth";
import {FACEBOOK_AUTH_CONFIG, GOOGLE_AUTH_CONFIG, NORMAL_AUTH_CONFIG} from "../../../constants";

const Login = () => {
	let [loginWithFacebookBtnLoading, setLoginWithFacebookBtnLoading] = useState(false);
	let [loginWithGoogleBtnLoading, setLoginWithGoogleBtnLoading] = useState(false);
	let [loginWithEmailBtnLoading, setLoginWithEmailBtnLoading] = useState(false);

	let [loginWithFacebookBtnDisabled, setLoginWithFacebookBtnDisabled] = useState(false);
	let [loginWithGoogleBtnDisabled, setLoginWithGoogleBtnDisabled] = useState(false);
	let [loginWithEmailBtnDisabled, setLoginWithEmailBtnDisabled] = useState(false);

	let [email, setEmail] = useState('');
	let [password, setPassword] = useState('');

	let {setToken} = useToken();
	let navigate = useNavigate();
	let location = useLocation();
	let from = location.state?.from?.pathname || "/dashboard";
	let {setIsAuthenticated} = useAuth();

	let getAccessTokenForSocialLogin = async (data) => {
		try {
			let response = await Auth.socialLogin(data);
			if (response.status === 200) {
				setToken('access_token', response?.data?.access_token);
				setIsAuthenticated(true);
				navigate(from, { replace: true });
				window.location.reload();
			}
		} catch (e) {
			setLoginWithFacebookBtnLoading(false);
			setLoginWithEmailBtnLoading(false);
			setLoginWithFacebookBtnDisabled(false);
			setLoginWithGoogleBtnDisabled(false);
			setLoginWithGoogleBtnLoading(false);
			setLoginWithEmailBtnDisabled(false);
			message.error("An error occurred.");
		}
	}

	let handleLoginWithFacebook = async () => {
		/*
		* login with Facebook
		* */

		setLoginWithFacebookBtnLoading(true);
		setLoginWithFacebookBtnDisabled(true);
		setLoginWithGoogleBtnDisabled(true);
		setLoginWithEmailBtnDisabled(true);

		let form = new FormData();
		form.append("grant_type", "convert_token");
		form.append("client_id", FACEBOOK_AUTH_CONFIG.CLIENT_ID);
		form.append("backend", FACEBOOK_AUTH_CONFIG.BACKEND_NAME);

		try {
			window.FB.login(
				response => {
					form.append("token", response?.authResponse?.accessToken);

					getAccessTokenForSocialLogin(form);
				}, {scope: 'public_profile,email'}
			)
		} catch (e) {
			setLoginWithEmailBtnLoading(false);
			setLoginWithFacebookBtnDisabled(false);
			setLoginWithFacebookBtnLoading(false);
			setLoginWithGoogleBtnDisabled(false);
			setLoginWithEmailBtnDisabled(false);
			message.error("An error occurred. Please check your internet connection and try again.");
		}
	}

	let loginWithGoogle = useGoogleLogin({
		onSuccess: tokenResponse => {
			let form = new FormData();
			form.append("grant_type", "convert_token");
			form.append("client_id", GOOGLE_AUTH_CONFIG.CLIENT_ID);
			form.append("backend", GOOGLE_AUTH_CONFIG.BACKEND_NAME);
			form.append("token", tokenResponse.access_token);
			getAccessTokenForSocialLogin(form);
		},
		onError: () => {
			setLoginWithEmailBtnLoading(false);
			setLoginWithFacebookBtnDisabled(false);
			setLoginWithFacebookBtnLoading(false);
			setLoginWithGoogleBtnDisabled(false);
			setLoginWithGoogleBtnLoading(false);
			setLoginWithEmailBtnDisabled(false);
			message.error("An error occurred.")
		},
		onNonOAuthError: () => {
			setLoginWithEmailBtnLoading(false);
			setLoginWithFacebookBtnDisabled(false);
			setLoginWithFacebookBtnLoading(false);
			setLoginWithGoogleBtnDisabled(false);
			setLoginWithGoogleBtnLoading(false);
			setLoginWithEmailBtnDisabled(false);
			message.error("An error occurred.");
		},
	});

	let handleLoginWithGoogle = async () => {
		setLoginWithGoogleBtnLoading(true);
		setLoginWithFacebookBtnDisabled(true);
		setLoginWithGoogleBtnDisabled(true);
		setLoginWithEmailBtnDisabled(true);

		loginWithGoogle();
	}

	let handleLoginWithEmail = async () => {
		if (email === "" || email.length <= 0) {
			message.error("Please enter a valid email");
			return;
		}

		if (password === "" || password.length <= 0) {
			message.error("Please enter a valid password");
			return;
		}

		setLoginWithEmailBtnLoading(true);
		setLoginWithFacebookBtnDisabled(true);
		setLoginWithGoogleBtnDisabled(true);
		setLoginWithEmailBtnDisabled(true);

		try {
			let response = await Auth.normalLogin({
				grant_type: "password",
				username: email,
				password: password,
				client_id: NORMAL_AUTH_CONFIG.CLIENT_ID,
				client_secret: NORMAL_AUTH_CONFIG.KEY
			})
			if (response.status === 200) {
				setToken('access_token', response?.data?.access_token);
				setIsAuthenticated(true);
				navigate(from, { replace: true });
				window.location.reload();
			}
		} catch (e) {
			setLoginWithEmailBtnLoading(false);
			setLoginWithFacebookBtnDisabled(false);
			setLoginWithGoogleBtnDisabled(false);
			setLoginWithEmailBtnDisabled(false);

			if (e.response && e.response.status === 400) {
				message.error(e?.response?.data?.error_description);
			} else {
				message.error("An error occurred. Please check your internet connection and try again.");
			}
		}
	}

	useEffect(() => {
		window.fbAsyncInit = () => {
			window.FB.init({
				appId            : FACEBOOK_AUTH_CONFIG.APP_ID,
				autoLogAppEvents : true,
				xfbml            : true,
				version          : 'v11.0'
			});
		};

		(function (d, s, id) {
			var js, fjs = d.getElementsByTagName(s)[0];
			if (d.getElementById(id)) { return; }
			js = d.createElement(s); js.id = id;
			js.src = "https://connect.facebook.net/en_US/sdk.js";
			fjs.parentNode.insertBefore(js, fjs);
		}(document, 'script', 'facebook-jssdk'));
	}, []);

	return (
		<div id="login-page" className="vh-100 d-flex justify-content-center align-items-center">
			<div className="p-4 m-3 shadow-lg rounded-1">
				<Image src={logo} preview={false} height={40} width={40} />
				<h4>Login</h4>
				<p className="small text-muted">Please enter your email and password to continue to your account.</p>

				<Form
					layout="vertical"
				>
					<Form.Item
						name="email"
						label="Email"
					>
						<Input
							size="large"
							placeholder="Email"
							className="rounded-1 border-0"
							onChange={e => setEmail(e.target.value)}
						/>
					</Form.Item>
					<Form.Item
						name="password"
						label="Password"
					>
						<Input.Password
							size="large"
							placeholder="Password"
							className="rounded-1 border-0"
							onChange={e => setPassword(e.target.value)}
						/>
					</Form.Item>

					<Form.Item>
						<Button
							onClick={handleLoginWithEmail}
							disabled={loginWithEmailBtnDisabled}
							loading={loginWithEmailBtnLoading}
							htmltype="submit"
							className="rounded-1 mt-3 border-0 text-dark"
							size="large"
							block
						>
							Login
						</Button>
					</Form.Item>

					<div className="d-flex justify-content-between">
						<p className="m-0">
							No account? <Link to="/signup"> Create one.</Link>
						</p>
						<p className="m-0">
							<Link to="/register">Forgot password</Link>
						</p>
					</div>
				</Form>
				<Divider className="border-light" plain>
					Or
				</Divider>

				<div>
					<Button
						loading={loginWithFacebookBtnLoading}
						disabled={loginWithFacebookBtnDisabled}
						onClick={handleLoginWithFacebook}
						style={{backgroundColor: "#1877F2"}}
						size="large"
						className="rounded-1 text-white border-0 mb-3"
						icon={<FacebookFilled style={{color: "white", fontSize: 24}}/>}
						block
					>
						Log in with Facebook
					</Button>

					<Button
						loading={loginWithGoogleBtnLoading}
						disabled={loginWithGoogleBtnDisabled}
						onClick={handleLoginWithGoogle}
						size="large"
						className="rounded-1 border-0 text-dark"
						icon={<GoogleLogo height={24} width={24} />}
						block
					>
						Sign in with Google
					</Button>
				</div>
			</div>
		</div>
	)
}

export default Login
