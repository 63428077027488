export const FACEBOOK_AUTH_CONFIG = {
	BACKEND_NAME: "facebook",
	CLIENT_ID: "FqqZ5MBe1m0OwyHRyrdZDYYj5FKarWGoBGA8vD78",
	APP_ID: "492783010194166"
}

export const GOOGLE_AUTH_CONFIG = {
	BACKEND_NAME: "google-oauth2",
	CLIENT_ID: "QecjaHFR1s82A59iquvvGqC3nG4NbLRmlVy5W3cx"
}

export const NORMAL_AUTH_CONFIG = {
	CLIENT_ID: "H5R9V856NxHAm5BlHXbEULxFggGdp50FN5nfx3WR",
	KEY: "49iSNYOSrg2rspsdVBHbGFd9lbW0gy4Um6pPSFKdD40eQ6AWijsGwOeQ12l8fnFtryEMY0G8E3N7hb1yThqwWdhzkGyFf0HXtmPudDuAnUKJBQRaOTYu56S3J0eXHbtt"
}

export const INDUSTRY_TYPES = [
	{ label: "Technology", value: "technology" },
	{ label: "Finance", value: "finance" },
	{ label: "Healthcare", value: "healthcare" },
	{ label: "Education", value: "education" },
	{ label: "Retail", value: "retail" },
	{ label: "Manufacturing", value: "manufacturing" },
	{ label: "Transportation", value: "transportation" },
	{ label: "Construction", value: "construction" },
	{ label: "Real Estate", value: "real_estate" },
	{ label: "Energy", value: "energy" },
	{ label: "Telecommunications", value: "telecommunications" },
	{ label: "Hospitality", value: "hospitality" },
	{ label: "Entertainment", value: "entertainment" },
	{ label: "Agriculture", value: "agriculture" },
	{ label: "Aerospace", value: "aerospace" },
	{ label: "Automotive", value: "automotive" },
	{ label: "Food and Beverage", value: "food_beverage" },
	{ label: "Non-profit", value: "non_profit" },
	{ label: "Legal", value: "legal" },
	{ label: "Consulting", value: "consulting" },
	{ label: "Media", value: "media" },
	{ label: "Pharmaceutical", value: "pharmaceutical" }
];
