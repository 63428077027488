import {Navigate, useNavigate} from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import {Button} from "antd";

const LandingPage = () => {
	let {isAuthenticated} = useAuth();
	let navigate = useNavigate();

	if (isAuthenticated) {
		return <Navigate to="/dashboard" replace />
	}

	return (
		<div className="d-flex vh-100 justify-content-center align-items-center flex-column">
			<h1 className="display-1 fw-bold mb-3">The Erector landing page</h1>
			<Button
				className="rounded-1 px-5"
				size="large"
				onClick={() => navigate("/login")}>Login</Button>
		</div>
	)
}

export default LandingPage;
