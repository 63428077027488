import {createContext, useEffect, useState} from "react";
import {Spin} from "antd";
import useToken from "../hooks/useToken";
import Users from "../services/users.service";
import NetworkErrorPage from "../pages/errors/NetworkErrorPage";
import {useNavigate} from "react-router-dom";

export const AuthContext = createContext(null);

const AuthProvider = ({ children }) => {
	let {accessToken, removeToken} = useToken()
	let [isAuthenticated, setIsAuthenticated] = useState(false);
	let [loading, setLoading] = useState(true);
	let [isNetworkError, setIsNetworkError] = useState(false);
	let [user, setUser] = useState(null);
	let navigate = useNavigate();

	let isTokenValid = async () => {
		try {
			let response = await Users.isAuthenticated();
			return response.status;
		} catch (e) {
			if (e.response && e.response.status) {
				return e.response.status
			} else {
				return null;
			}
		}
	}

	let getUser = async () => {
		try {
			let response = await Users.getUserById();
			return response.data
		} catch (e) {
			return null;
		}
	}

	useEffect(() => {
		if (!isAuthenticated) {
			if (accessToken === null || accessToken === undefined || accessToken === "") {
				setLoading(false);
				setIsAuthenticated(false);
			} else {
				isTokenValid()
					.then(response => {
						if (response === 200) {
							setIsAuthenticated(true);
							setLoading(false);
						} else if (response === 401) {
							removeToken();
							setIsAuthenticated(false);
							setLoading(false);
						} else if (response === null || response === undefined) {
							setIsNetworkError(true);
							setLoading(false);
						} else {
							setIsNetworkError(true);
						}
					})
			}
		}
	}, [accessToken, isAuthenticated, removeToken]);

	useEffect(() => {
		if (!user) {
			getUser()
				.then((response) => {
					if (response !== null) {
						setUser(response);
						if (!response.is_onboarded) {
							navigate("/onboarding");
							setLoading(false);
						} else {
							setLoading(false);
						}
					} else {
						setLoading(false);
					}
				})
		}
	}, [navigate, setUser, user])

	if (isNetworkError) {
		return <NetworkErrorPage />
	}

	if (loading) {
		return <Spin tip="Loading...Please wait" fullscreen />
	}

	return (
		<AuthContext.Provider value={{isAuthenticated, setIsAuthenticated, user, setUser}}>
			{children}
		</AuthContext.Provider>
	)
}

export default AuthProvider
