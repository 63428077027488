import httpCommon from "../http-common";

class UsersService {
	create(data) {
		return httpCommon.post('users/', data);
	}

	isAuthenticated() {
		return httpCommon.post('users/isAuthenticated');
	}

	getUserById() {
		return httpCommon.get('users/getUserById');
	}

	sendEmailVerificationCode() {
		return httpCommon.post('users/sendEmailVerificationCode');
	}

	verifyEmailVerificationCode(data) {
		return httpCommon.post('users/verifyEmailVerificationCode', data);
	}

	minimalUpdateUser(id, data) {
		return httpCommon.patchForm(`users/minimalUpdateUser/${id}`, data);
	}

	completeSignup(id, data) {
		return httpCommon.patch(`users/completeSignup/${id}`, data);
	}

	inviteTeamMember(data) {
		return httpCommon.post('users/inviteTeamMember', data);
	}

	newTeamMemberPasswordReset(data, token) {
		return httpCommon.post('users/newTeamMemberPasswordReset', data, {
			headers: {
				"Authorization": `Bearer ${token}`
			}
		});
	}
}

export default new UsersService();
